/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    blockquote: "blockquote",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-cholesteatoma",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-cholesteatoma",
    "aria-label": "what is cholesteatoma permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is cholesteatoma?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Cholesteatoma is a chronic, purulent inflammation of the middle ear caused by a proliferation of squamous epithelium from the outer auditory canal into the middle ear"), ". As a rule, the eardrum separates the middle ear from the outer auditory canal. In healthy people, the middle ear is lined with mucosal epithelium, and the outer auditory canal is lined with squamous epithelium."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If, for various reasons (e.g., a perforation or rupture of the eardrum), the squamous epithelium gets into the middle ear, it can cause a constant inflammation. This, in turn, can induce long-term bone destruction in the region of the tympanic cavity (middle ear) and the petrous bone. This is also called “chronic suppurative osteitis (otitis media cholesteatomatosa)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Put simply, ", React.createElement(_components.strong, null, "cholesteatoma is the name given to the abnormal skin growth in the middle ear"), ". ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/otitis-media",
    className: "c-md-a"
  }, "This can cause infections in the area"), ". The continuous growth of the cholesteatoma can result in the bones in the middle ear being destroyed leading to hearing loss, dizziness and in rare cases facial muscle paralysis."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The following contains important information about cholesteatoma, its forms, and its associated symptoms. We have also provided an overview of possible treatments for cholesteatoma."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "3-types-of-cholesteatoma",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-types-of-cholesteatoma",
    "aria-label": "3 types of cholesteatoma permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3 types of Cholesteatoma:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "1. Congenital cholesteatoma: Primary acquired cholesteatoma or retraction cholesteatoma")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This type of cholesteatoma is very rare, and occurs when squamous epithelium residue remains in the middle ear during development of the embryo. The tympanic membrane is intact. ", React.createElement(_components.strong, null, "This type of cholesteatoma is very rare"), ", and occurs when squamous epithelium residue remains in the middle ear during development of the embryo. The tympanic membrane is intact."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "2. Primary acquired cholesteatoma or retraction cholesteatoma")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "This form of cholesteatoma is due to a ventilation malfunction in the middle ear"), ", often caused by insufficient tube permeability. The tube is a conduit between the middle ear and the nasopharyngeal space. This malfunction leads to low pressure in the middle ear cells, and consequently the tympanic membrane creates a so-called retraction pocket (retraction of the tympanic membrane). This happens mostly in the pars flaccida region of the tympanic membrane, also called Shrapnell’s membrane. The reason for this is that the pars flaccida, unlike the other areas of the tympanic membrane, is very limp. Squamous epithelium accumulates in this retraction pocket, and a cholesteatoma results from it after a few years."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "3. Secondary acquired cholesteatoma:")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This type of cholesteatoma is ", React.createElement(_components.strong, null, "caused by a pre-existing peripheral defect in the tympanic membrane"), " which allows the epithelium to penetrate into the middle ear."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms",
    "aria-label": "symptoms permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you do have Cholesteatoma, then you might notice the following symptoms. Some of the symptoms exist depending on how long you have been suffering for."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Hearing loss – this is mostly temporary but if it isn’t diagnosed earlier then it could be permanent. (", React.createElement(_components.a, {
    href: "/hearing-loss",
    className: "c-md-a"
  }, "more information"), ")"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Vertigo – this is linked to loss of balance and a sense of dizziness (", React.createElement(_components.a, {
    href: "/resources/hearing-loss/bppv/",
    className: "c-md-a"
  }, "more information"), ")"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Noticeable fluid from the ear with a foul smell"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Pain behind or in your ear over a period of time (", React.createElement(_components.a, {
    href: "/resources/hearing-loss/earache",
    className: "c-md-a"
  }, "more information"), ")"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Facial muscle paralysis – this is the symptom of severe cases of cholesteatoma."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you notice any of these symptoms, then it might be important to contact a health medical professional such as an ENT specialist or ", React.createElement(_components.a, {
    href: "/audiologists",
    className: "c-md-a"
  }, "an audiologist"), ". Sometimes Cholesteatoma doesn’t have symptoms and may be identified incidentally."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Complications occur either due to the inflammation or increased displacement by the cholesteatoma. For example, there may be a destruction of the ossicular chain. Thus, the sound is no longer transmitted adequately to the inner ear, resulting in a hearing impairment."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "diagnosis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diagnosis",
    "aria-label": "diagnosis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diagnosis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It is important to get it diagnosed early so the specialist can help find the right treatment option. ", React.createElement(_components.strong, null, "The longer you leave the more permanent the damage"), ". Treatment and diagnosis would be done by an audiologist or an ENT specialist. Diagnosis is usually determined by one of three different methods:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Ear Microscopy")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The typical findings here are a defect of the tympanic membrane, white epithelium scales, and eventually bone erosion (a progressive destruction) of the ear canal wall, close to the eardrum."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "CT study (computer tomography)")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Here, the exact extent of bone destruction can usually be determined with more accuracy."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Hearing test (audiogram)")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Typically, cholesteatomata patients suffer from ", React.createElement(_components.a, {
    href: "/hearing-loss/conductive",
    className: "c-md-a"
  }, "conductive hearing loss, i.e., a hearing disorder that only affects the outer ear"), ". If the cholesteatoma is so far advanced that the inner ear is already affected, a ", React.createElement(_components.a, {
    href: "/hearing-loss/sensorineural",
    className: "c-md-a"
  }, "so-called sensorineural hearing loss"), " is present."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "treatment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#treatment",
    "aria-label": "treatment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Treatment"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If one wants to achieve complete healing of a cholesteatoma, ", React.createElement(_components.strong, null, "the only option is surgery"), ". If the ear becomes infected, the infections can be treated medically with antibotics and topical treatments. However, if you have the surgery then all inflammatory or destructive tissue in the mastoid and the tympanic cavity must be removed. Only during the second phase, usually a second operation, can hearing improvement be achieved. Generally, this is possible with a tympanoplasty, in which the tympanic membrane and, if necessary, also the ossicular chain, are restored."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If facial paralysis or a nystagmus (eye twitch, in the event of infection of the inner ear, specifically the balance organ) occurs, surgery is imperative. Conservative treatment, such as non-surgical treatment, should only be used in preparation for absolutely essential surgery. This works similarly to treating a simple chronic otitis media with local measures (antibiotic ear drops) or with “systemic” antibiotic therapy."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Is the surgery safe?")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When surgery is mentioned, people worry whether it’s safe or not. With this surgery the risks are the same as if the surgery isn’t done:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Total hearing loss in the ear that is operated on"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Severe tinnitus – constant ringing in the ear, in severe cases it’s hard to control"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Balance issues and vertigo – the sufferer might have issues with their balance and suffer from vertigo post the operation"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Facial nerve paralysis – this is a rare case scenario"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Meningitis"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These are rare case scenarios and most people just suffer from mild hearing loss after the surgery. The consequences of not getting the surgery can be a lot more severe."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The prognosis for cholesteatoma very much depends on which complications occur over the course of the condition. ", React.createElement(_components.strong, null, "With successful surgery and complete removal of the cholesteatoma, the chance of recovery is very good"), ". Many patients are completely treated and have no further issues post the surgery."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "cholesteatoma-and-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cholesteatoma-and-hearing-aids",
    "aria-label": "cholesteatoma and hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Cholesteatoma and hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Surgery is the only option to treat the infections and the overall health issues related to Cholesteatoma. However, as mentioned above one of the post-surgery consequence is; mild to moderate hearing loss. This can be dealt with easily by investing in hearing aids. After the surgery you could consult your audiologist or medical specialist and they will be able to advise you on which hearing aids to choose or alternatives."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have any other questions, then you can contact our specialist consultants who can provide you some answers."), "\n", React.createElement(LandingPageCta, {
    copy: "SIGN UP TO TRY HEARING AIDS",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
